import React, { useEffect, useState } from "react";
import AppStore from "../../config/AppStore";
import { sendRequestGetMonthlyRevenueReports } from "../../view_logics/Reports/Reports";
import { getMonthName, getYear } from "../../../utils/common_functions";
import { useSelector } from "react-redux";
import Spinner from "../../components/common/Spinner";

const MonthlyRevenueReportsList = () => {
  const { publisherId } = useSelector((state) => state?.login);

  let store = AppStore.getAppStoreInstance();
  const [apiData, setApiData] = useState([]);
  const [reportsList, setReportsList] = useState([]);
  console.log("🚀 ~ MonthlyRevenueReportsList ~ reportsList:", reportsList);
  const [displayItems, setDisplayItems] = useState([]);
  console.log("🚀 ~ MonthlyRevenueReportsList ~ displayItems:", displayItems)
  const [displayMonth, setDisplayMonth] = useState(null);
  // const [selectedYear, setSelectedYear] = useState("2024");
  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );
  const lastTenYears = Array.from({ length: 10 }, (_, index) =>
    (new Date().getFullYear() - index).toString()
  );

  const [reportsFilterLoading, setReportsFilterLoading] = useState(false);

  const inputObj = {
    accessToken: store.getState().login.accessToken,
    url: "reports",
    data: {
      publisher_id: publisherId != null ? publisherId : "1",
      year: selectedYear,
    },
    loadingHandler: setReportsFilterLoading,
    dataHander: setApiData,
  };

  useEffect(() => {
    if (Array.isArray(apiData)) {
      const transformedData = apiData.map((item) => ({
        ...item,
        billing_month: getMonthName(item.billing_month),
        year: getYear(item.billing_month),
      }));
      console.log("🚀 ~ useEffect ~ transformedData:", transformedData);
      setReportsList(transformedData);
      setReportsFilterLoading(false);
    }
  }, [apiData]);

  const fetchMonthlyReports = async (date) => {
    try {
      let response;
      // Fetch records for the selected year
      setReportsFilterLoading(true);
      response = await sendRequestGetMonthlyRevenueReports({
        ...inputObj,
        data: {
          ...inputObj.data,
          year: date,
        },
      });

      setReportsList(response);
    } catch (error) {
      console.log("Error fetching monthly reports:", error);
      setReportsFilterLoading(false);
    }
  };

  useEffect(() => {
     fetchMonthlyReports(selectedYear);
   }, []);

  const applyFilterHandler = async () => {
   // setReportsFilterLoading(true);
    let updatedYear = getYear(selectedYear);
    fetchMonthlyReports(updatedYear);
  };

  // const sortBy = (key) => {
  //   if (key === "desc") {
  //     setReportsList((prevList) => {
  //       const sortedData = [...prevList];
  //       const firstItem = sortedData.shift();
  //       sortedData.push(firstItem);
  //       return sortedData;
  //     });
  //   } else {
  //     setReportsList((prevList) => {
  //       const sortedData = [...prevList];
  //       const lastItem = sortedData.pop();
  //       sortedData.unshift(lastItem);
  //       return sortedData;
  //     });
  //   }
  // };

  const nestedItemDisplay = (items) => {
    const groupedData = {};

    // Iterate through the array and group objects by date
    if (items.length > 0) {
      items.forEach((item) => {
        const { website_domain } = item;
        if (!groupedData[website_domain]) {
          groupedData[website_domain] = [item];
        } else {
          groupedData[website_domain].push(item);
        }
      });
    }

  };

  const sumOfFieldForRow = (row, fieldName) => {
    const sum = row.items.reduce(
      (total, item) => total + Number(item[fieldName]),
      0
    );
    return sum.toFixed(1);
  };

  return (
    <div className="col-12 col-xl-12 position-relative">
      <div className="card">
      <div className="w-100 px-3 pt-3 d-flex">
          <div className="w-100 d-flex gap-1">
            <div className="w-100 d-flex gap-2 align-items-center">
              <div className="d-flex w-25">
                <select
                  className="form-select"
                  aria-label="select example"
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  {lastTenYears.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              <div>
              <div className="ms-3">
              {reportsFilterLoading ? (
                <button
                  className="btn btn-primary d-flex gap-1 align-items-center"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Apply
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={applyFilterHandler}
                >
                  Apply
                </button>
              )}
              </div>
            </div>

              </div>
            </div>
          </div>
        </div>
        <hr />
        <table id="reports" className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Month</th>

              <th scope="col">Year</th>
              <th scope="col">Reported Revenue</th>
              <th scope="col">Final Revenue</th>

              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {reportsList?.length > 0 ? (
              reportsList.map((report, index) => (
                report.items?.length > 0 &&
                <React.Fragment key={index}>

                  <tr className="table-info">
                    <td
                      title="Show Items"
                      className="cursor-pointer"
                      onClick={() => {
                        setDisplayMonth(displayMonth === index ? null : index);
                        if (report.items?.length > 0) {
                          const aggregatedData = {};

                          report.items.forEach((item) => {
                            const {
                              website_domain,
                              final_revenue,
                              reported_revenue,
                            } = item;
                            if (!aggregatedData[website_domain]) {
                              aggregatedData[website_domain] = {
                                website_domain: website_domain,
                                final_revenue: final_revenue,
                                reported_revenue: reported_revenue,
                              };
                            } else {
                              aggregatedData[website_domain].final_revenue +=
                                final_revenue;
                              aggregatedData[website_domain].reported_revenue +=
                                reported_revenue;
                            }
                          });
                          const aggregatedArray = Object.values(aggregatedData);

                          console.log('item data', aggregatedData)
                          setDisplayItems(
                            displayMonth === report.billing_month
                              ? []
                              : aggregatedArray
                          );
                        } else {
                          setDisplayItems([])
                        }
 
                      }}
                    >
                      {report.billing_month}
                      <i
                        className={`${
                          report.items?.length > 0 && displayMonth === index
                            ? "fa-solid fa-circle-minus"
                            : "fa-solid fa-circle-plus"
                        } ps-3`}
                      ></i>
                    </td>
                    <td>{report.year}</td>
                    <td>
                      {"$" + sumOfFieldForRow(report, "reported_revenue")}
                    </td>
                    <td>{"$" + sumOfFieldForRow(report, "final_revenue")}</td>

                    <td>{report.is_invoice_paid === 1 ? "Paid" : "Unpaid"}</td>
                  </tr>

                  {nestedItemDisplay(report.items)}

                  {displayMonth === index &&
                    displayItems.length > 0 &&
                    displayItems.map((nestedItem, nestedIndex) => {
                      
                      return (
                        <tr key={nestedIndex}>
                          <td
                          // colSpan="1"
                          // style={{
                          //   paddingLeft: "",
                          //   verticalAlign: "middle",
                          // }}
                          >
                            {" "}
                            {nestedItem.website_domain}
                          </td>
                          <td
                          // colSpan="1"
                          // style={{
                          //   paddingLeft: "40px",
                          //   verticalAlign: "middle",
                          // }}
                          >
                            {" "}
                            {/* {nestedItem.ad_unit_name} */}
                          </td>
                          <td
                          // colSpan="1"
                          // style={{
                          //   paddingLeft: "17px",
                          //   verticalAlign: "middle",
                          // }}
                          >
                            {" "}
                            {"$" + nestedItem.reported_revenue.toFixed(1)}
                          </td>
                          <td
                            colSpan="2"
                            style={{
                              paddingLeft: "17px",
                              verticalAlign: "left",
                            }}
                          >
                            {" "}
                            {"$" + nestedItem.final_revenue.toFixed(1)}
                          </td>
                        </tr>
                      )
                    })}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="12">No records found …</td>
              </tr>
            )}
          </tbody>
        </table>
        <hr />
        {/* <PaginationComponent /> */}
      </div>
      {reportsFilterLoading && <Spinner/>}
    </div>
  );
};
export default MonthlyRevenueReportsList;
