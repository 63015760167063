import React, { useEffect, useState } from "react";
import AppStore from "../../config/AppStore";
import {
  sendRequestGetMonthlyRevenueReportsAdmin,
  sendRequestUpdateMonthlyFinalRevenueReportsAdmin,
  sendRequestUpdateMonthlyStatusReportsAdmin,
} from "../../view_logics/Reports/Reports";
import { getMonth, getYear } from "date-fns";
import { addLeadingZero } from "../../../utils/common_functions";
import DatePickerField from "../../components/common/DatePickerField";
import ModalComponent from "../../components/common/Modal";
import _ from "lodash";
import Spinner from "../../components/common/Spinner";

const MonthlyRevenueReportsListAdmin = () => {
  const [reportsFilterLoading, setReportsFilterLoading] = useState(false);
  console.log(
    "🚀 ~ MonthlyRevenueReportsListAdmin ~ reportsFilterLoading:",
    reportsFilterLoading
  );

  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedYear, setSelectedYear] = useState(new Date());

  const [selectedReportToUpdate, setSelectedReportToUpdate] = useState(null);
  console.log(
    "🚀 ~ MonthlyRevenueReportsListAdmin ~ selectedReportToUpdate:",
    selectedReportToUpdate
  );


  let store = AppStore.getAppStoreInstance();
  const [apiData, setApiData] = useState([]);
  const [reportsList, setReportsList] = useState([]);
  const [displayItems, setDisplayItems] = useState([]);
  console.log(
    "🚀 ~ MonthlyRevenueReportsListAdmin ~ displayItems:",
    displayItems
  );

  const [finalRevenueValue, setFinalRevenueValue] = useState(
    parseFloat(0).toFixed(2)
  );
  const [reviewStatus, setReviewStatus] = useState("pending");
  const [paidUnPaid, setPaidUnPaid] = useState("unpaid");
  const [notesValue, setNotesValue] = useState("");
  const [isFinalRevenueSuccess, setIsFinalRevenueSuccess] = useState(false);

  const [collapseStatus, setCollapseStatus] = useState({});

  const handleRowClick = (index) => {
    setCollapseStatus((prevStatus) => ({
      ...prevStatus,
      [index]: !prevStatus[index], 
    }));
  };

  const handleReviewStatusChange = (e) => {
    setReviewStatus(e.target.value);
  };

  const inputObj = {
    accessToken: store.getState().login.accessToken,
    url: "reports",
    data: { month_date: "" },
    loadingHandler: setReportsFilterLoading,
    dataHander: setApiData,
  };

  useEffect(() => {
    if (Array.isArray(apiData)) {
      // // Render grouped data in a table
      const sortedData = _.chain(apiData)
        .map((item) => ({
          name: `${item.first_name} ${item.last_name}`,
          ...item,
        }))
        .sortBy("name")
        .groupBy("name")
        .map((value, key) => ({
          name: key,
          reported_revenue: _.sumBy(value, "reported_revenue"),
          final_revenue: _.sumBy(value, "final_revenue"),
          items: value,
        }))
        .value();
      console.log("sortedData >>>", sortedData);
      setReportsList(sortedData);
      setReportsFilterLoading(false);
    }
  }, [apiData]);

  const fetchMonthlyReports = async (date) => {
    try {
      // debugger;
      // Fetch records for the selected year
      const response = await sendRequestGetMonthlyRevenueReportsAdmin({
        ...inputObj,
        data: { month_date: date },
      });

      setReportsList(response);
    } catch (error) {
      console.log("Error fetching monthly reports:", error);
      setReportsFilterLoading(false);
    }
  };

  useEffect(() => {
    applyFilterHandler();
    // fetchMonthlyReports(monthDate);
  }, []);

  const applyFilterHandler = async () => {
    setReportsFilterLoading(true);
    let updatedMonth = getMonth(selectedMonth) + 1;
    let updatedYear = getYear(selectedYear);
    let formatedMonth;
    if (updatedMonth) {
      formatedMonth = addLeadingZero(updatedMonth);
    }

    if (formatedMonth && updatedYear) {
      let postedDate = `${formatedMonth}/${updatedYear}`;
      fetchMonthlyReports(postedDate);
    }
  };

  // let inputObj2 = {
  //   accessToken: store.getState().login.accessToken,
  //   data: {
  //     invoice_id: 1,
  //     status_type: "",
  //     status: 1,
  //   },
  // };

  let inputObj3 = {
    accessToken: store.getState().login.accessToken,
    data: {
      publisher_id: 1,
      month_date: "10/2023",
      reported_revenue: 20.1,
      final_revenue: 20,
      description: "",
    },
  };

  let inputObj4 = {
    accessToken: store.getState().login.accessToken,
    data: {
      payout_id: 1,
      status_type: "unpaid",
    },
  };

  // const updateMonthlyReports = async (data) => {
  //   try {
  //     let response;
  //     // Fetch records for the selected year
  //     response = await sendRequestUpdateMonthlyRevenueReportsAdmin({
  //       ...inputObj2,
  //       data: { invoice_id: 1, status_type: "", status: 1 },
  //     });
  //     setReportsList(response);
  //   } catch (error) {
  //     console.log("Error fetching monthly reports:", error);
  //     setReportsFilterLoading(false);
  //   }
  // };

  const formatMonthYear = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensures month is two digits
    const year = date.getFullYear();
    return `${month}/${year}`;
  };

  const updateFinalRevenueMonthlyReports = async (modalRef) => {
    // debugger;
    if (reviewStatus === "approved") {
      try {
        const monthYear = formatMonthYear(selectedReportToUpdate.items[0].date);
        // const finalRevenue = parseFloat(finalRevenueValue);
        // const reportedRevenue =
        //   selectedReportToUpdate.items[0].reported_revenue === null
        //     ? 0
        //     : selectedReportToUpdate.items[0].reported_revenue;

          await sendRequestUpdateMonthlyFinalRevenueReportsAdmin({
          ...inputObj3,
          data: {
            publisher_id: selectedReportToUpdate.items[0].publisher_id,
            month_date: monthYear,
            // reported_revenue: reportedRevenue,
            // final_revenue: finalRevenue,
            // description: "",
            items: selectedReportToUpdate.items.map((item) => ({
              website_id: item.website_id,
              reported_revenue: item.reported_revenue != null ? item.reported_revenue : 0,
              final_revenue: item.final_revenue != null ? item.final_revenue : 0,
              note: item.description === null ? '' : item.description
            })),
          },
          dataHander: (res) => {
            if (res.status === "success") {
              setReportsList(res);
              setIsFinalRevenueSuccess(true);
              applyFilterHandler();
              // 2
            }
            // if (modalRef.current) {
            //   const modalInstance = Modal.getInstance(modalRef.current);
            //   if (modalInstance) {
            //     modalInstance.hide();
            //     modalInstance.dispose();
            //   } else {
            //     console.error("Modal instance not found.");
            //   }
            // } else {
            //   console.error("Modal reference not found.");
            // }
            // fetchMonthlyReports(`${selectedMonth}/${selectedYear}`);
          },
        });
      } catch (error) {
        console.log("Error fetching update monthly reports:", error);
      }
    } else {
      console.log("Review status is not approved, request not sent.");
    }
  };

  const updateMonthlyStatusReport = async (data) => {
    if (paidUnPaid === "paid") {
      try {
        // let response;
          await sendRequestUpdateMonthlyStatusReportsAdmin({
          ...inputObj4,
          data: {
            payout_id: selectedReportToUpdate.items[0].payout_id,
            status_type: paidUnPaid,
          },
          dataHander: (res) => {
            if (res.status === "success") {
              //3
              // fetchMonthlyReports(`${selectedMonth}/${selectedYear}`);
              applyFilterHandler();
              setPaidUnPaid("paid");
            } else {
              setPaidUnPaid("unpaid");
            }
          },
        });
      } catch (error) {
        console.log(
          "Error Updating monthly paid unpaid status reports:",
          error
        );
      }
    } else {
      setPaidUnPaid("unpaid");
      console.log("Unpaid is selected, request not send ");
    }
  };

  const paidUnpaidHandler = (e) => {
    setPaidUnPaid(e.target.value);
  };

  const notesHandler = () => { 
    const newNotes = notesValue;
    displayItems.description = newNotes;
  
    const updatedReport = { ...selectedReportToUpdate };
    const updatedItems = updatedReport.items.map(item => (item === displayItems ? displayItems : item));
    updatedReport.items = updatedItems;
    setSelectedReportToUpdate(updatedReport);
   
  };

  return (
    <>
      <ModalComponent
        id={"update_final_revenue"}
        saveHandler={() => {
          if (!selectedReportToUpdate) return;
          const reportSum =
            _.sumBy(selectedReportToUpdate.items, "final_revenue") || 0;
          // const diff = finalRevenueValue - reportSum;
          const newReport = selectedReportToUpdate;
          if (newReport.items.length === 1) {
            newReport.items[0].final_revenue = parseFloat(finalRevenueValue);
          } else {
            _.forEach(newReport.items, (item, index) => {
              const itemValue =
                reportSum === 0
                  ? finalRevenueValue / newReport.items.length
                  : (item.final_revenue / reportSum) * finalRevenueValue;
              newReport.items[index].final_revenue = itemValue;
            });
          }
          newReport.final_revenue = parseFloat(finalRevenueValue);
          setSelectedReportToUpdate(newReport);
          if (displayItems.length > 0) setDisplayItems(newReport.items);
          setReportsList(
            _.map(reportsList, (report) => {
              return report.name === newReport.name ? newReport : report;
            })
          );
        }}
        modalHeading={"Update Final Revenue"}
      >
        <input
          className="form-control"
          aria-label="Default input example"
          value={finalRevenueValue}
          onChange={(e) => {
            setFinalRevenueValue(e.target.value);
          }}
          onClick={() => {
            // setIsShowInputValue(true);
          }}
        />
      </ModalComponent>
      <ModalComponent
        id={"update_invoice_status"}
        saveHandler={updateFinalRevenueMonthlyReports}
        modalHeading={"Update Invoice Status"}
      >
        <select
          className="form-select"
          aria-label="Default select example"
          onChange={handleReviewStatusChange}
        >
          <option value="pending">Pending</option>
          <option value="approved">Approved</option>
        </select>
      </ModalComponent>

      <ModalComponent
        id={"update_is_invoice_paid_status"}
        saveHandler={updateMonthlyStatusReport}
        modalHeading={"Update Invoice Paid Status"}
      >
        <select
          className="form-select"
          aria-label="Default select example"
          onChange={(e) => paidUnpaidHandler(e)}
        >
          <option value="unpaid">Unpaid</option>
          <option value="paid">Paid</option>
        </select>
      </ModalComponent>

      <ModalComponent
        id={"update_notes"}
        saveHandler={notesHandler}
        modalHeading={"Update Notes"}
      >
        <textarea
          type="textarea"
          component="textarea"
          id="notes"
          name="notes"
          value={notesValue}
          onChange={(e) => {
            setNotesValue(e.target.value);
          }}
          className="form-control"
          // required
          rows="4"
          // value={props?.formData?.custom_css}
          // onChange={(e) => {
          //   props.setFormData((formData) => ({
          //     ...formData,
          //     custom_css: e.target.value,
          //   }));
          // }}
        />
      </ModalComponent>

      <ModalComponent
        id={"view_notes"}
        modalHeading={"View Notes"}
      >
        <div>{notesValue}</div>
      </ModalComponent>

      <div className="col-12 col-xl-12 position-relative">
        <div className="card">
          <div className="w-100 px-3 pt-3 d-flex gap-2 align-items-center">
            <div className="w-50 d-flex gap-2 align-items-center">
              <div className="w-50 d-flex gap-1 align-items-center">
                <label className="form-label mb-0" htmlFor="advertiserId">
                  Month:
                </label>
                <DatePickerField
                  value={selectedMonth}
                  setValue={setSelectedMonth}
                  mode={"month"}
                />
              </div>

              <div className="w-50 d-flex gap-1 align-items-center">
                <label className="form-label mb-0" htmlFor="advertiserId">
                  Year:
                </label>
                <DatePickerField
                  value={selectedYear}
                  setValue={setSelectedYear}
                  mode={"year"}
                />
              </div>
            </div>

            <div>
              {reportsFilterLoading ? (
                <button
                  className="btn btn-primary d-flex gap-2 align-items-center"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Apply
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={applyFilterHandler}
                >
                  Apply
                </button>
              )}

              {/* <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#month_report_admin"
              >
                Launch modal
              </button> */}
            </div>
          </div>
          <hr />
          <table id="reports" className="table table-striped">
            <thead>
              <tr>
                {/* <th scope="col">Month</th> */}
                <th scope="col">Name</th>
                {/* <th scope="col">Year</th> */}
                <th scope="col">Reported Revenue</th>
                <th scope="col">Final Revenue</th>

                <th scope="col">Review</th>
                <th scope="col">Status</th>
                <th scope="col">Notes</th>
              </tr>
            </thead>
            <tbody>
              {reportsList?.length > 0 ? (
                reportsList?.map((report, ind) => {
                  const isExpanded = collapseStatus[ind];
                  return (
                    <React.Fragment key={ind}>
                      <tr className="table-info">
                        <td
                          onClick={() => {
                            handleRowClick(ind)
                            // setDisplayIndex(ind);
                            // setDisplayItems(
                            //   displayItems?.length > 0 ? [] : report.items
                            // );
                          }}
                        >
                          {`${report.name}`}
                          <i
                            className={`${
                              isExpanded
                                ? "fa-solid fa-circle-minus"
                                : "fa-solid fa-circle-plus"
                            } ps-3`}
                          ></i>
                        </td>
                        {/* <td>{report.year}</td> */}

                        <td>
                          {"$" +
                            (report.reported_revenue?.toFixed(2) ??
                              (0.0).toFixed(2))}
                        </td>
                        <td>
                          {"$" +
                            (report.final_revenue?.toFixed(2) ??
                              (0.0).toFixed(2))}{" "}
                           {report.items[0].payout_status === 0 || report.items[0].payout_status === null  ? ( 
                            <i
                              className="fa-solid fa-pen"
                              data-bs-toggle="modal"
                              data-bs-target="#update_final_revenue"
                              onClick={() => {
                                setSelectedReportToUpdate(report);
                                setFinalRevenueValue(parseFloat(report.final_revenue).toFixed(2));
                              }}
                            ></i>
                           ) : (
                            " "
                          )} 
                        </td>
                        <td>
                          {report.items[0].payout_status === 0 || report.items[0].payout_status === null
                            ? "Pending"
                            : "Approved"}{" "}
                          {(report.items[0].payout_status === 0 || report.items[0].payout_status === null)  && (
                            <i
                              className="fa-solid fa-pen"
                              data-bs-toggle="modal"
                              data-bs-target="#update_invoice_status"
                              onClick={() => setSelectedReportToUpdate(report)}
                            ></i>
                          )}
                        </td>
                        <td>
                          {/* //1 */}
                          {(report.items[0].is_payout_paid === 0 || report.items[0].is_payout_paid === null)
                            ? "Unpaid"
                            : "Paid"}{" "}
                          {
                            (report.items[0].is_payout_paid === 0 || report.items[0].is_payout_paid !== null) &&
                            report.items[0].payout_status !== 1 &&
                            isFinalRevenueSuccess === true ? (
                              <i
                                className="fa-solid fa-pen"
                                data-bs-toggle="modal"
                                data-bs-target="#update_is_invoice_paid_status"
                                onClick={() => setSelectedReportToUpdate(report)}
                              ></i>
                            ) : (
                              ""
                            )
                          }
                        </td>
                        <td></td>
                      </tr>

                      {isExpanded &&
                        report.items.map((nestedItem, nestedIndex) => {
                          return (
                            // <></>
                            <tr key={nestedIndex}>
                              <td> {nestedItem.website_name}</td>
                              <td>
                                {`$${
                                  nestedItem.reported_revenue?.toFixed(2) ??
                                  (0.0).toFixed(2)
                                }`}
                              </td>
                              <td>
                                {`$${
                                  nestedItem.final_revenue?.toFixed(2) ??
                                  (0.0).toFixed(2)
                                }`}
                              </td>
                              <td>
                                {nestedItem.payout_status === 0 || nestedItem.payout_status === null
                                  ? "Pending"
                                  : "Approved"}{" "}
                              </td>
                              <td>
                                {nestedItem.is_payout_paid === 0 || nestedItem.is_payout_paid === null
                                  ? "Unpaid"
                                  : "Paid"}{" "}
                              </td>
                              <td>
                                {/* {nestedItem.description ? "..." : ""}{" "} */}
                                { nestedItem.description ?
                                  <i
                                  className="fa-regular fa-note-sticky"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view_notes"
                                  onClick={() => {
                                    if(nestedItem.description) {
                                      setNotesValue(nestedItem.description)
                                    }else {
                                      setNotesValue("")
                                    }
                                  }
                                  }
                                ></i>
                                : ""
                                }
                                {" "}
                                
                                {report.items[0].payout_status === 0 || report.items[0].payout_status === null  ? ( 
                                <i
                                  className="fa-solid fa-pen"
                                  data-bs-toggle="modal"
                                  data-bs-target="#update_notes"
                                  onClick={() => {
                                    if(nestedItem.description) {
                                      setNotesValue(nestedItem.description)
                                    }else {
                                      setNotesValue("")
                                    }
                                    setDisplayItems(nestedItem)
                                    setSelectedReportToUpdate(report)
                                  }
 
                                  }
                                ></i>
                                 ):("")
                                } 
                              </td>
                            </tr>
                          );
                        })}
                    </React.Fragment>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="12">No records found …</td>
                </tr>
              )}
            </tbody>
            {reportsFilterLoading && <Spinner />}
          </table>
          <hr />
          {/* <PaginationComponent /> */}
        </div>
        {/* {apiData == null && <Spinner/>} */}
      </div>
    </>
  );
};
export default MonthlyRevenueReportsListAdmin;
