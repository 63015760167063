import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, useFormik, Field, Form, ErrorMessage } from "formik";

import AppStore from "../../config/AppStore";
import Spinner from "../../components/common/Spinner";

import { sendRequestGetAffiliateString } from "../../view_logics/AdvertiserOffers/AdvertiserOffer";
import { sendBlockedAllowedFormSubmitRequest } from "../../view_logics/AdvertiserOffers/CreateAdvertiserOffer";

import "choices.js/public/assets/styles/choices.min.css";
import moment from "moment";

const BlockedAllowedTab = ({ advertiserOfferInfo }) => {
  const { id } = useParams();
  const store = AppStore.getAppStoreInstance();

  const [savedValues, setSavedValues] = useState(null);

  const [blockedSources, setBlockedSources] = useState(
    advertiserOfferInfo.blocked_sources
  );

  const formik = useFormik({
    initialValues: {
      advertiser_id: advertiserOfferInfo.advertiser_id,
      advertiser_offer_id: advertiserOfferInfo.id,
      block_reason: "",
      block_type: "explicit_block",
      concated_v: "",
      concated_v2_v5: "",
      created_date: "",
      end_ip_range: 0,
      id: 1,
      is_recursive: 0,
      start_ip_range: 0,
      sub1: "",
      sub2: "",
      sub3: "",
      sub4: "",
      sub5: "",
      blocked: [],
      advertiser_id: advertiserOfferInfo.advertiser_id,
    },
    onSubmit: (values) => {},
  });

  const [savedSuccessfully, setSavedSuccessfully] = useState(false);

  const [affiliateString, setAffiliateString] = useState("");
  const [affiliateObject, setaffiliateObject] = useState({
    pid: "",
    concatedSubIds: "",
  });

  const [data, setData] = useState({
    selectedBlockedId: "",
    blockedList: {
      recursive: [],
      nonRecursive: [],
      selectedBlockedId: "",
    },
    isRecursive: false,
    available: {
      selectedIds: [],
      data: [],
    },
    customerSource: {
      selectedBlockedSourceId: "",
      v1: "",
      v2: "",
      v3: "",
      v4: "",
      v5: "",
    },
  });

  useEffect(() => {
    console.log("the adversiter values is >>>>>>>>",advertiserOfferInfo);
    if (
      advertiserOfferInfo?.blocked_sources &&
      advertiserOfferInfo?.blocked_sources?.length > 0
    ) {
      let recursive = [];
      let nonRecursive = [];
      advertiserOfferInfo?.blocked_sources?.forEach((obj) => {
        obj.is_recursive === 1 && recursive.push(obj);
        obj.is_recursive === 0 && nonRecursive.push(obj);
      });
      let tempData = { ...data };
      tempData.blockedList.recursive = recursive;
      tempData.blockedList.nonRecursive = nonRecursive;

      setData(tempData);
    }
  }, [advertiserOfferInfo]);

  function formatSubValues(obj) {
    const { sub1, sub2, sub3, sub4, sub5 } = obj;

    let formattedString = `${sub1}`;

    if (sub2) {
      formattedString += ` -> ${sub2}`;
    }

    if (sub3) {
      formattedString += ` -> ${sub3}`;
    }

    if (sub4) {
      formattedString += ` -> ${sub4}`;
    }

    if (sub5) {
      formattedString += ` -> ${sub5}`;
    }

    return formattedString;
  }

  const affiliateStringRequest = () => {
    if (affiliateObject.pid !== "") {
      const fetchData = async () => {
        try {
          const accessToken = store.getState().login.accessToken;
          const inputObj = {
            accessToken,
            dataHander: onAffiliateStringResponse,
            pid: affiliateObject.pid,
            concatedSubIds: affiliateObject.concatedSubIds,
          };
          const response = await sendRequestGetAffiliateString(inputObj);
        } catch (error) {
          console.log("Error fetching data:", error);
        }
      };

      fetchData();
    } else {
    }
  };

  const onAffiliateStringResponse = (res) => {
    setData((prevData) => ({
      ...prevData,
      available: {
        ...prevData.available,
        data: res,
      },
    }));
  };

  const handleChangeAffiliateString = (event) => {
    const inputValue = event.target.value;
    setAffiliateString(inputValue);

    const values = inputValue.split("_");
    const pid = values[0];
    const concatedSubIds = values[1];

    setaffiliateObject({
      pid,
      concatedSubIds,
    });
  };

  const onChangeBlockedButton = (event) => {
    let value = event?.target?.value;

    if (value === "Recursive" || value === "Non-Recursive") {
      return;
    }

    setData((prev) => {
      return {
        ...prev,
        blockedList: {
          ...prev.blockedList,
          selectedBlockedId: value,
        },
      };
    });
  };

  const onClickDownButton = () => {
    if (data?.available?.selectedIds?.length === 0) {
      return;
    }

    let selectedAvailableId = data?.available?.selectedIds[0];
    let found = data?.available?.data.find(
      (obj) => parseInt(obj.id) === parseInt(selectedAvailableId)
    );

    {
      const tempData = { ...data };
      tempData.available.selectedIds = tempData?.available?.selectedIds?.filter(
        (recordId) => parseInt(recordId) !== parseInt(selectedAvailableId)
      );
      tempData.available.data = tempData?.available?.data?.filter(
        (obj) => parseInt(obj.id) !== parseInt(selectedAvailableId)
      );

      if (data.isRecursive) {
        tempData.blockedList.recursive.push(found);
      } else {
        tempData.blockedList.nonRecursive.push(found);
      }

      tempData.customerSource.v1 = "";
      tempData.customerSource.v2 = "";
      tempData.customerSource.v3 = "";
      tempData.customerSource.v4 = "";
      tempData.customerSource.v5 = "";

      setData(tempData);
    }

    return;
  };

  const isEmptySubFields = () => {
    let count = 0;

    data.customerSource.v1 && (count += 1);
    data.customerSource.v2 && (count += 1);
    data.customerSource.v3 && (count += 1);
    data.customerSource.v4 && (count += 1);
    data.customerSource.v5 && (count += 1);

    if (count === 0) {
      return true;
    } else {
      return false;
    }
  };

  // Add Custom Source
  const onClickCustomSourceHandler = () => {
    if (isEmptySubFields()) {
      return;
    }

    // Update Blocked Source
    if (data?.available?.selectedIds?.length > 0) {
      let selectedAvailableId = data.available.selectedIds[0];
      let updatedRecord = data?.available?.data?.find(
        (obj) => parseInt(obj.id) === parseInt(selectedAvailableId)
      );

      {
        // Remove selected data from Available MultiSelect Field
        const tempData = { ...data };
        tempData.available.selectedIds =
          tempData?.available?.selectedIds?.filter(
            (id) => parseInt(id) !== parseInt(selectedAvailableId)
          );
        tempData.available.data = tempData?.available?.data?.filter(
          (obj) => parseInt(obj.id) !== parseInt(selectedAvailableId)
        );

        updatedRecord.sub1 = data.customerSource.v1;
        updatedRecord.sub2 = data.customerSource.v2;
        updatedRecord.sub3 = data.customerSource.v3;
        updatedRecord.sub4 = data.customerSource.v4;
        updatedRecord.sub5 = data.customerSource.v5;

        data.isRecursive && tempData.blockedList.recursive.push(updatedRecord);
        !data.isRecursive &&
          tempData.blockedList.nonRecursive.push(updatedRecord);

        setData(tempData);
      }

      return;
    }

    // Add new Blocked Source
    const tempNewBlockedData = {
      id: Math.floor(Math.random() * (1000 - 200 + 1)) + 200,
      is_recursive: data.isRecursive,
      website_id: 0,
      website_source_id: 0,
      advertiser_id: 0,
      advertiser_offer_id: 0,

      block_type: "explicit_allow",

      sub1: data.customerSource.v1,
      sub2: data.customerSource.v2,
      sub3: data.customerSource.v3,
      sub4: data.customerSource.v4,
      sub5: data.customerSource.v5,
      concated_v: "",
      concated_v2_v5: "",

      start_ip_range: 0,
      end_ip_range: 0,
      block_reason: "",

      created_date: moment().toISOString(),
    };

    let tempData = { ...data };

    if (data.isRecursive) {
      tempData.blockedList.recursive.push(tempNewBlockedData);
      setData(tempData);
    } else {
      tempData.blockedList.nonRecursive.push(tempNewBlockedData);
      setData(tempData);
    }
  };

  const onChangeSub = (event, values, fieldName) => {
    try {
      const inputValue = event.target.value;

      switch (fieldName) {
        case "sub1": {
          let tempData = { ...data };
          tempData.customerSource.v1 = inputValue;
          setData(tempData);
          break;
        }

        case "sub2": {
          let tempData = { ...data };
          tempData.customerSource.v2 = inputValue;
          setData(tempData);
          break;
        }

        case "sub3": {
          let tempData = { ...data };
          tempData.customerSource.v3 = inputValue;
          setData(tempData);
          break;
        }

        case "sub4": {
          let tempData = { ...data };
          tempData.customerSource.v4 = inputValue;
          setData(tempData);
          break;
        }

        case "sub5": {
          let tempData = { ...data };
          tempData.customerSource.v5 = inputValue;
          setData(tempData);
          break;
        }

        default: {
          break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onClickUpButton = () => {
    if (data?.blockedList?.selectedBlockedId === "") {
      return;
    }

    let id = data?.blockedList?.selectedBlockedId;
    let arr = [
      ...data?.blockedList?.recursive,
      ...data?.blockedList?.nonRecursive,
    ];

    let found = arr.find((obj) => parseInt(obj.id) === parseInt(id));

    if (found) {
      // update available obj
      setData((prev) => {
        return {
          ...prev,
          available: {
            ...prev.available,
            data:
              prev.available?.data?.length > 0
                ? [...prev.available?.data, found]
                : [found],
          },
        };
      });

      // update blockedList obj
      setData((prev) => {
        return {
          ...prev,
          blockedList: {
            ...prev.blockedList,
            recursive: data?.blockedList?.recursive?.filter(
              (obj) => parseInt(obj.id) !== parseInt(id)
            ),
            nonRecursive: data?.blockedList?.nonRecursive?.filter(
              (obj) => parseInt(obj.id) !== parseInt(id)
            ),
            selectedBlockedId: "",
          },
        };
      });
    }
  };

  const onChangeIsRecursive = () => {
    let tempData = { ...data };
    tempData.isRecursive = !tempData?.isRecursive;

    setData(tempData);
  };

  const onClickAvailableField = (event) => {
    let value = parseInt(event?.target?.value);

    // remove item
    if (data?.available?.selectedIds?.includes(value)) {
      setData((prev) => {
        return {
          ...prev,
          available: {
            ...prev.available,
            selectedIds: prev?.available?.selectedIds?.filter(
              (id) => id !== value
            ),
          },
        };
      });

      return;
    }

    // add item
    let found = data.available.data.find((obj) => parseInt(obj.id) === value);
    console.log("found: ", found);

    if (found) {
      // setFormValues(values, found);

      let tempData = { ...data };
      tempData.customerSource.v1 = found?.sub1;
      tempData.customerSource.v2 = found?.sub2;
      tempData.customerSource.v3 = found?.sub3;
      tempData.customerSource.v4 = found?.sub4;
      tempData.customerSource.v5 = found?.sub5;
      setData(tempData);

      setData((prev) => {
        return {
          ...prev,
          available: {
            ...prev.available,
            selectedIds: [found?.id],
          },
        };
      });
    }
  };

  function convertToCommaSeparatedStrings(input) {
    return input.map((item) => {
      // Extract the values of each object's properties in order
      const values = ["sub1", "sub2", "sub3", "sub4", "sub5"]
        .map((key) => item[key] || "") // Use the key or an empty string if the key is missing
        .filter((value) => value); // Filter out any empty strings
      return values.join(",").toLowerCase();
    });
  }

  const createUpdateBlockedAllowedList = (event, values) => {
    event.preventDefault();
    console.log("Values: ", values);

    const fetchData = async () => {
      try {
        const accessToken = store.getState().login.accessToken;

        let recursive_options = [];
        let blocked_sources = [];

        if (data?.blockedList?.recursive?.length > 0) {
          data?.blockedList?.recursive?.forEach((obj) =>
            recursive_options.push(1)
          );

          blocked_sources = [
            ...convertToCommaSeparatedStrings(data?.blockedList?.recursive),
          ];
        }

        if (data?.blockedList?.nonRecursive?.length > 0) {
          data?.blockedList?.nonRecursive?.forEach((obj) =>
            recursive_options.push(0)
          );
          blocked_sources = [
            ...blocked_sources,
            ...convertToCommaSeparatedStrings(data?.blockedList?.nonRecursive),
          ];
        }

        const websiteId =
          data.blockedList.recursive.length > 0
            ? data.blockedList.recursive[0].website_id
            : data.blockedList.nonRecursive.length > 0
            ? data.blockedList.nonRecursive[0].website_id
            : "";

        // const advertiserOfferId =
        //   data.blockedList.recursive.length > 0
        //     ? data.blockedList.recursive[0].advertiser_offer_id
        //     : data.blockedList.nonRecursive.length > 0
        //     ? data.blockedList.nonRecursive[0].advertiser_offer_id
        //     : null;
        
        const blockTypeElement = document.getElementById("block_type");
        const blockTypeValue = blockTypeElement ? blockTypeElement.value : null;
        console.log('<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>')
        console.log(blockTypeValue);
        const inputObj = {
          website_id: websiteId,
          offer_id: values.advertiser_offer_id,
          advertiser_id: values.advertiser_id,
          block_type: values.block_type,
          blocked_sources,
          recursive_options,
          block_reason: values?.block_reason,
        };

        console.log("inputobj value >>>>>>>>>:",inputObj);

        const response = await sendBlockedAllowedFormSubmitRequest(
          accessToken,
          inputObj,
          (res) => {
            console.log("savedSuccessfully response >>", res);

            if (res.status == "success") setSavedSuccessfully(true);
          }
        );
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  };

  return (
    <div className="position-relative">
      <div className="tab-pane active" id="tab-1" role="tabpanel">
        {savedSuccessfully ? (
          <div className="alert alert-success alert-dismissible" role="alert">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
            <div className="alert-message">
              <strong>Success!</strong> Updated successfully!
            </div>
          </div>
        ) : (
          ""
        )}
        <Formik
          initialValues={savedValues || formik.initialValues}
          onSubmit={createUpdateBlockedAllowedList}
          //   validationSchema={AdvertiserOfferSchema}
          enableReinitialize
        >
          {({ errors, touched, handleChange, isValid, values }) => {
            return (
              <Form
                onSubmit={(event) =>
                  createUpdateBlockedAllowedList(event, values)
                }
              >
                <input
                  type="hidden"
                  name="_token"
                  value="C5Oe8oLVgFK6wo5dqcWEYVrjvHXO1yrmMsiMvqnf"
                />
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Blocked/Allowed List</h5>
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="block_type">
                          Type
                        </label>
                        <Field
                          as="select"
                          name="block_type"
                          id="block_type"
                          className="form-control"
                        >
                          <option value="explicit_block">Explicit Block</option>
                          <option value="explicit_allowed">Explicit Allowed</option>
                        </Field>
                        <ErrorMessage
                          name="block_type"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="affiliateString">
                          Affiliate String
                        </label>
                        <Field
                          type="text"
                          id="affiliateString"
                          name="affiliateString"
                          className="form-control"
                          placeholder="Affiliate Source"
                          onChange={handleChangeAffiliateString}
                          value={affiliateString}
                        />
                        <ErrorMessage
                          name="affiliateString"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-4 col-md-1 d-flex flex-column justify-content-end">
                        <button
                          type="button"
                          onClick={affiliateStringRequest}
                          className="btn btn-primary"
                        >
                          <i className="fa-solid fa-magnifying-glass"></i>
                        </button>
                      </div>
                    </div>

                    <div class="row">
                      {/* Available */}
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="available">
                          Available
                        </label>

                        <Field
                          as="select"
                          multiple
                          name="available"
                          id="available"
                          className="form-control"
                          onClick={onClickAvailableField}
                          value={data?.available?.selectedIds}
                        >
                          {data?.available?.data?.map((obj, index) => (
                            <option key={obj?.id} value={obj?.id}>
                              {formatSubValues(obj)}
                            </option>
                          ))}
                        </Field>
                      </div>

                      {/* Add Customer Service */}
                      <div className="mb-3 col-md-6 ">
                        <div className="row">
                          <div className="mb-3 col-md-6">
                            <label className="form-label" htmlFor="sub1">
                              Sub1
                            </label>
                            <Field
                              type="text"
                              id="sub1"
                              name="sub1"
                              className="form-control"
                              value={data?.customerSource?.v1}
                              onChange={(e) => onChangeSub(e, values, "sub1")}
                            />
                            <ErrorMessage
                              name="sub1"
                              component="div"
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            />
                          </div>

                          <div className="mb-3 col-md-6">
                            <label className="form-label" htmlFor="sub2">
                              Sub2
                            </label>
                            <Field
                              type="text"
                              id="sub2"
                              name="sub2"
                              className="form-control"
                              value={data?.customerSource?.v2}
                              onChange={(e) => onChangeSub(e, values, "sub2")}
                            />
                            <ErrorMessage
                              name="sub2"
                              component="div"
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            />
                          </div>

                          <div className="mb-3 col-md-6">
                            <label className="form-label" htmlFor="sub3">
                              Sub3
                            </label>
                            <Field
                              type="text"
                              id="sub3"
                              name="sub3"
                              className="form-control"
                              value={data?.customerSource?.v3}
                              onChange={(e) => onChangeSub(e, values, "sub3")}
                            />
                            <ErrorMessage
                              name="sub3"
                              component="div"
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            />
                          </div>

                          <div className="mb-3 col-md-6">
                            <label className="form-label" htmlFor="sub4">
                              Sub4
                            </label>
                            <Field
                              type="text"
                              id="sub4"
                              name="sub4"
                              className="form-control"
                              value={data?.customerSource?.v4}
                              onChange={(e) => onChangeSub(e, values, "sub4")}
                            />
                            <ErrorMessage
                              name="sub4"
                              component="div"
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            />
                          </div>

                          <div className="mb-3 col-md-6">
                            <label className="form-label" htmlFor="sbu5">
                              Sub5
                            </label>
                            <Field
                              type="text"
                              id="sub5"
                              name="sub5"
                              className="form-control"
                              value={data?.customerSource?.v5}
                              onChange={(e) => onChangeSub(e, values, "sub5")}
                            />
                            <ErrorMessage
                              name="sub5"
                              component="div"
                              className="invalid-feedback"
                              style={{ display: "block" }}
                            />
                          </div>
                        </div>

                        <div className="mt-3 d-flex justify-content-center">
                          <button
                            type="button"
                            onClick={onClickCustomSourceHandler}
                            class="btn btn-primary"
                          >
                            Add Custom Source
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Recursive */}
                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <span style={{ marginRight: "20px" }}>Recursive</span>

                        <label
                          className="form-check form-check-inline"
                          htmlFor="recursive"
                        >
                          <Field
                            type="checkbox"
                            id="recursive"
                            name="recursive"
                            className="form-check-input"
                            checked={data?.isRecursive}
                            onChange={onChangeIsRecursive}
                          />
                          <span className="form-check-label ml-10">Yes</span>
                        </label>
                      </div>
                    </div>

                    {/* Up and Down Icon */}
                    <div className="row">
                      <div className="col-md-6 d-flex justify-content-center">
                        {/* Up */}
                        <button
                          type="button"
                          className="btn btn-primary me-3"
                          onClick={onClickUpButton}
                        >
                          <i className="fa-solid fa-arrow-up"></i>
                        </button>

                        {/* Down */}
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={onClickDownButton}
                        >
                          <i className="fa-solid fa-arrow-down"></i>
                        </button>
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-md-6">
                        <label
                          className="form-label"
                          htmlFor="affiliateString2"
                        >
                          Affiliate String
                        </label>
                        <Field
                          type="text"
                          id="affiliateString2"
                          name="affiliateString2"
                          className="form-control"
                          placeholder="Search Affiliate Source e.g(2_14_14299)"
                        />
                        <ErrorMessage
                          name="affiliateString2"
                          component="div"
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        />
                      </div>
                      <div className="mb-4 col-md-1 d-flex flex-column justify-content-end">
                        <button type="button" className="btn btn-primary">
                          <i className="fa-solid fa-magnifying-glass"></i>
                        </button>
                      </div>
                    </div>

                    <div>
                      <div className="mb-3 col-md-6">
                        <label className="form-label" htmlFor="blocked">
                          Blocked / Allowed
                        </label>

                        <Field
                          as="select"
                          multiple
                          name="blocked"
                          id="blocked"
                          className="form-control"
                          onChange={onChangeBlockedButton}
                          // value={selectedBlockedValues}
                          value={[data?.blockedList?.selectedBlockedId || ""]}
                        >
                          <option key={"Recursive"} value={"Recursive"}>
                            Recursive
                          </option>

                          {data?.blockedList?.recursive?.map((obj, index) => (
                            <option key={obj?.id} value={obj?.id}>
                              {formatSubValues(obj)}
                            </option>
                          ))}

                          <option key={"Non-Recursive"} value={"Non-Recursive"}>
                            Non-Recursive
                          </option>

                          {data?.blockedList?.nonRecursive?.map(
                            (obj, index) => (
                              <option key={obj?.id} value={obj?.id}>
                                {formatSubValues(obj)}
                              </option>
                            )
                          )}

                          {/* {concatenatedStrings.map((str, index) => (
                            <option key={index} value={str}>
                              {str}
                            </option>
                          ))} */}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="form-group row mb-0">
                  <div className="d-flex justify-content-end">
                    {/* <button type="button" className="btn btn-secondary me-3">
                      close
                    </button>
                    <button type="button" className="btn btn-primary me-3">
                      Save affiliate change
                    </button> */}
                    <button 
                      type="submit"
                      className="btn btn-primary"
                      disabled={blockedSources.length === 0 && !(data.customerSource.v1 || data.customerSource.v2 || data.customerSource.v3 || data.customerSource.v4 || data.customerSource.v5 )}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

      {id && !advertiserOfferInfo.id && <Spinner />}
    </div>
  );
};

export default BlockedAllowedTab;
