import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import AppStore from "../../config/AppStore";
import { isUserTokenValid, readUserToken } from "../../utils/common_functions";

import { sendRequestGetPublishers } from "../../view_logics/Publishers";
import { editPublisherLinkClick } from "../../view_logics/Publishers/EditPublisher";
import { setUserType } from "../../view_logics/LoginSlice";

const PublishersList = (store) => {
  const [data, setData] = useState([]);
  const { username, userType, mainUser } = useSelector(state=> state?.login)

  const dispatch = useDispatch()

  store = AppStore.getAppStoreInstance();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const inputObj = {
    accessToken: store.getState().login.accessToken,
    url: "editPublisher",
    history: navigate,
    data: [],
    dataHander: setData,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await sendRequestGetPublishers(inputObj);
        let jsonData = inputObj.data;
        console.log("returned data is:");
        console.log(inputObj);
        // setData(jsonData);  //this is bad request is recursion
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const editPublisherHandler = (item) => {
    const url = '/publishers/edit/' + item.id;
    navigate(url)
    console.log("🚀 ~ file: WebsitesList.js:40 ~ WebsitesList ~ item:", item)
  }

  const changeUserType = (publisher) =>{
    // let type = userType == "admin" ? "publisher" : "admin"
    dispatch(setUserType({type: "publisher", id: publisher?.id, publisherCompany: publisher?.company_name, publisherName: `${publisher?.first_name} ${publisher?.last_name}`}))
    navigate('/dashboard')
 }

  return (
    <div className="col-12 col-xl-12">
      <div className="card">
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Company</th>
              <th>Revenue share</th>
              <th>Actions</th>
              {mainUser == "admin" &&
              <th style={{width: '140px'}}></th>
  }
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((publisher, index) => (
                <tr
                  key={publisher.id}
                  className={publisher.is_active === 0 ? "table-secondary" : index % 2 === 0 ? "table-success" : ""}
                >
                  <td>{publisher.first_name + " " + publisher.last_name}</td>
                  <td>{publisher.email}</td>
                  <td className="d-none d-md-table-cell">
                    {publisher.company_name}
                  </td>
                  <td className="d-none d-md-table-cell">
                    {`${publisher.revenue_share}/${100 - publisher.revenue_share}`}
                  </td>
                  <td className="table-action">
                    <a
                      onClick={(e) => {
                        e.preventDefault()
                        editPublisherHandler(publisher);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-edit-2 align-middle"
                      >
                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                      </svg>
                    </a>
                    {/* <a href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash align-middle">
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      </svg>
                    </a> */}
                  </td>

                    {mainUser == "admin" &&
                  <td>
                    {/* <button className="" style={{fontSize: '12px'}}>Act as Publisher</button> */}
                    <button type="button" className="btn btn-secondary btn-sm" onClick={()=> changeUserType(publisher)}>Act as Publisher</button>
                  </td>
                  }
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">Loading data...</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default PublishersList;
