import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  sendRequestGetAdUnis,
  showEmbedScriptLinkHandler,
} from "../../view_logics/AddUnits";
import { editPublisherLinkClick } from "../../view_logics/Publishers/EditPublisher";
import { editAddUnitLinkClick } from "../../view_logics/AddUnits/EditAddUnit";
import { isUserTokenValid, readUserToken } from "../../utils/common_functions";
import AppStore from "../../config/AppStore";

import EditModal from "../../components/Modal";
import { capitalizeFirstWord } from "../../../utils/common_functions";
import { sendRequestGetPublisherWebsites } from "../../view_logics/Websites/Website";
import { sendRequestGetPublishers } from "../../view_logics/Publishers";
import Spinner from "../../components/common/Spinner";


const AddUnitsList = (store) => {
  const [data, setData] = useState([]);
  const [websitesList, setWebsitesList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const { userType, publisherId } = useSelector((state) => state?.login);
  const [publishersList, setPublishersList] = useState([]);
  const [selectedPublisher, setSelectedPublisher] = useState(0);
  const [selectedWebsite, setSelectedWebsite] = useState(0);
  const [websitesFilterLoading, setWebsitesFilterLoading] = useState(false);
  const [websitesListLoading, setWebsitesListLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
 

  const handleEditClick = (data) => {
    // console.warn(data);
    setModalData(data);
    setIsModalOpen(true);
  };

  const handleCloseModal = (one) => {
    setIsModalOpen(false);
  };

  store = AppStore.getAppStoreInstance();
  const navigate = useNavigate(); // we can pass navigation to clickhandler
  const inputObj = {
    accessToken: store.getState().login.accessToken,
    history: navigate,
    dataHander: setData,
  };

  const getPublishersInputObj = {
    accessToken: store.getState().login.accessToken,
    url: "",
    dataHander: setPublishersList,
  };

  const getPublishersWebsiteInputObj = {
    accessToken: store.getState().login.accessToken,
    url: "editAdvertiser",
    history: navigate,
    loadingHandler: setWebsitesListLoading,
  };

  // useEffect(() => {
  //   if (Array.isArray(data)) {
  //     console.log("useEffect data ",data);
  //    setData(data)
  //   }
  // }, [data]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userType === "publisher") {
          inputObj.publisher_id = publisherId;
        }
        setFilterLoading(true);
        inputObj.loadingHandler = setFilterLoading;
       
        await sendRequestGetAdUnis(inputObj);
        await sendRequestGetPublishers(getPublishersInputObj);
       
        // let jsonData = inputObj.data;
        // setData(jsonData);  //this is bad request is recursion
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const showType = (type) => {
    console.log("🚀 ~ showType ~ type:", type)
    let displayValue = '';
    if(type == 'custom_template'){
      displayValue = 'Custom Full Page'
    }
    if(type == 'custom_ad_sequence'){
      displayValue = 'Custom Ad Sequence'
    }
    if(displayValue != ''){
      return displayValue
    }else{
      return capitalizeFirstWord(type)
    }
  }

  const publisherChangeHandler = (e) => {
    const selected = e.target.value;
    setSelectedWebsite("");
    setSelectedPublisher(selected);
    const fetchData = async () => {
      try {
       // setWebsitesFilterLoading(true);
       // getPublishersWebsiteInputObj.loadingHandler = setWebsitesFilterLoading;
        getPublishersWebsiteInputObj.data = [];
        getPublishersWebsiteInputObj.publisherId = selected;
        getPublishersWebsiteInputObj.dataHander = setWebsitesList; // you setting website in this state you don't need to update setData again
        await sendRequestGetPublisherWebsites(getPublishersWebsiteInputObj);

      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };
    fetchData();
  };

  const websiteChangeHandler = (e) => {
    const selected = e.target.value;
    setSelectedWebsite(selected);
  }

  const applyFilterHandler = () => {

    // if (selectedPublisher || selectedWebsite) {
        const fetchData = async () => {
          try {
            const inputObj = {
              accessToken: store.getState().login.accessToken,
              url: "editAdvertiser",             
            };
            if (selectedPublisher) {
              inputObj.publisher_id = selectedPublisher;
            }
            if (selectedWebsite) {
              inputObj.website_id = selectedWebsite;
            }
            inputObj.dataHander = setData; 
            setFilterLoading(true);
            inputObj.loadingHandler = setFilterLoading;
            await sendRequestGetAdUnis(inputObj);
          } catch (error) {
            console.log("Error fetching data:", error);
          }
        };
        fetchData();
      // }
    }
  
  return (
    <div className="col-12 col-xl-12">
      <div className="card">
      {userType == "admin" && (
          <>
            <div className="col-md-12 col-lg-12 col-xl-10 col-xxl-8 d-flex align-items-center pt-3 px-2 gap-2">
              <div className="d-flex w-75 gap-1 align-items-center">
                <label className="form-label mb-0" htmlFor="advertiserId">
                  Publisher:
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={selectedPublisher}
                  onChange={publisherChangeHandler}
                >
                  <option value={""}>Select all</option>
                  {publishersList.length > 0
                    ? publishersList.map((publisher) => (
                        <option key={publisher.id} value={publisher.id}>
                          {publisher?.first_name + " " + publisher?.last_name}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
              <div className="d-flex w-75 gap-1 align-items-center">
                <label className="form-label mb-0" htmlFor="advertiserId">
                  Website:
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={selectedWebsite}
                  onChange={websiteChangeHandler}
                >
                  <option value={""}>Select all</option>
                  {websitesList.length > 0
                    ? websitesList.map((website) => (
                        <option key={website.id} value={website.id}>
                          {website?.name}
                        </option>
                      ))
                    : ""}
                </select>
              </div>

              {filterLoading ? (
                <button
                  className="btn btn-primary d-flex gap-2 align-items-center"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Apply
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={applyFilterHandler}
                  // disabled={!selectedPublisher}
                >
                  Apply
                </button>
              )}
            </div>
            <hr />
          </>
        )}
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Website</th>
              <th>Type</th>
              <th className="d-none d-md-table-cell">No of Offers</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((advertiser, index) => (
                <tr
                  key={advertiser.unique_id}
                  className={index % 2 === 0 ? "table-success" : ""}
                >
                  <td className="text-truncate" style={{ maxWidth: "130px" }}>{advertiser.id}</td>
                  <td className="text-truncate" style={{ maxWidth: "130px" }}>{`${advertiser.website_name}_${advertiser.name}`}</td>
                  <td className="text-truncate" style={{ maxWidth: "100px" }}>
                    {advertiser.name}
                  </td>
                  <td>{showType(advertiser.type)}</td>
                  <td className="d-none d-md-table-cell">
                    {advertiser.no_of_offers}
                  </td>
                  <td className="table-action">
                    <span
                    className="cursor-pointer"
                      onClick={() => {
                        inputObj.id = advertiser.id;
                        editAddUnitLinkClick(inputObj);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-edit-2 align-middle"
                      >
                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                      </svg>
                    </span>
                    &nbsp;
                    <span
                    className="cursor-pointer"
                      onClick={() => {
                        inputObj.id = advertiser.id;
                        inputObj.openModalHandler = handleEditClick;
                        showEmbedScriptLinkHandler(inputObj);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="27"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-code align-middle me-2"
                      >
                        <polyline points="16 18 22 12 16 6"></polyline>
                        <polyline points="8 6 2 12 8 18"></polyline>
                      </svg>
                    </span>
                    {/* <a href="#">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash align-middle">
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      </svg>
                    </a> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No data found...</td>
              </tr>
            )}
          </tbody>
          {filterLoading && <Spinner />}
        </table>
        {isModalOpen ? (
          <EditModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            data={modalData}
          />
        ) : (
          ""
        )}
      </div>
      
    </div>
  );
};
export default AddUnitsList;
